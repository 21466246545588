import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import {
  Col,
} from 'components/Layout/Grid';

import {
  BUNTING,
  COLOR_CTA_HOVER_BG,
  FONT_SIZE_16,
  JAVA_GREEN,
  MEDIUM_STONE,
  MONA_LISA_PINK,
} from 'helper/constants/styles';
import { smallTablet, largeTablet, desktop } from 'helper/constants/mediaRules';

export const TextTitle = styled.h3`
  color: ${JAVA_GREEN};
  font-weight: 700;
  padding-top: 0.5rem;
`;

export const TextBody = styled.p`
  font-size: ${FONT_SIZE_16};
  margin-bottom: 0;
`;

export const SectionContainer = styled(Col)`
  margin: 2rem auto 0;
  padding: 0;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${MEDIUM_STONE};
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  width: 100%;

  ${largeTablet(css`
    width: 35rem;
  `)}

  ${desktop(css`
    width: 44rem;
  `)}
`;

export const StyledLink = styled.li`
  color: ${JAVA_GREEN};
  margin-bottom: 0.8rem;
  font-size: ${FONT_SIZE_16};
`;

export const PageCTA = styled(Link)`
  display: inline-block;
  background-color: ${MONA_LISA_PINK};
  padding: 10px 24px;
  border-radius: 4px;
  color: ${BUNTING} !important;
  font-size: 16px;
  font-weight: 700 !important;
  margin: 0 0 3rem 0;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
    background-color: ${COLOR_CTA_HOVER_BG};
  }

  ${smallTablet(css`
    margin: 1rem 0 3rem 0;
  `)}

  ${desktop(css`
    margin: 1rem 0 3rem 0;
  `)}
`;
