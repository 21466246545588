import React from 'react';
import styled, { css } from 'styled-components';

import { Container, Col } from 'components/Layout/Grid';

import { smallTablet, largeTablet, desktop } from 'helper/constants/mediaRules';
import {
  JAVA_GREEN,
} from 'helper/constants/styles';

const HeaderContainer = styled(Container)`
  margin: 2rem auto 4rem;
  text-align: center;

  ${smallTablet(css`
    margin: 3rem auto 4rem;
  `)}

  ${largeTablet(css`
    margin: 4rem auto 0rem;
  `)}

  ${desktop(css`
    margin: 4rem auto 0rem;
  `)}
`;

export const HeaderCol = styled(Col)`
  padding: 0;
  z-index: 1;
  min-height: 16rem;
  margin: 0 auto;
  ${largeTablet(css`
    padding-left: 18px;
    padding-right: 18px;
  `)}
`;

const HeaderTitleText = styled.h1`
  color: ${JAVA_GREEN};
  font-weight: 400;
  margin: 0.6rem 0 0 0;
`;

const HeaderText = styled.p`
  font-weight: 400;
`;

const PlatformHeader = ({ image, title, body }) => (
  <HeaderContainer id={`qa-${title.split(' ').join('-').toLowerCase()}-page`} width={[1, 1, 1, 0.85]}>
    <HeaderCol>
      <img id="page-header-image" src={image} alt="" />
      <HeaderTitleText>{title}</HeaderTitleText>
      <HeaderText>{body}</HeaderText>
    </HeaderCol>
  </HeaderContainer>
);

export default PlatformHeader;
