import React from 'react';
import styled, { css } from 'styled-components';

import strings from 'helper/localization/localization.js';
import implementMimiCardIcon from 'S3/images/implement_mimi_card_icon.svg';
import implementMimiCardPhone from 'S3/images/implement_mimi_card_phone.svg';
import operatingSystemsIcon from 'S3/images/operating_systems_icon.svg';
import processingVisual from 'S3/images/processing_visual.svg';

import {
  Col,
} from 'components/Layout/Grid';
import {
  PageCTA,
  SectionContainer,
  StyledLink,
  TextTitle,
  TextBody,
} from 'components/IntegrateMimiUi';

import { desktop } from 'helper/constants/mediaRules';

const Container = styled(SectionContainer)`
  text-align: center;
`;

const TextContainer = styled(Col)`
  padding-top: 3.8rem;
  margin: 0 auto 5rem;
  text-align: center;

  &:last-of-type {
    padding-top: 0;
  }
`;

const ImageList = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: initial;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 0;

  &:last-of-type {
    margin-bottom: 4rem;
  }

  ${desktop(css`
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-bottom: 2rem;
    flex-direction: row;
    &:last-of-type {
      flex-direction: row-reverse;
    }
  `)}
`;

const ListContainer = styled.ul`
  text-align: left;
  list-style: disc;
  margin-top: 2rem;
  ${desktop(css`
    margin-left: 3.5rem;
  `)}
`;

const configList1 = [
  strings.userConfigList1,
  strings.userConfigList2,
  strings.userConfigList3,
  strings.userConfigList4,
];

const configList2 = [
  strings.userConfigList5,
  strings.userConfigList6,
  strings.userConfigList7,
  strings.userConfigList8,
];

const ImplementMimiCard = () => {
  const list1 = configList1.map(list => (
    <StyledLink key={list}>{list}</StyledLink>
  ));
  const list2 = configList2.map(list => (
    <StyledLink key={list}>{list}</StyledLink>
  ));
  return (
    <Container>
      <TextContainer width={[1, 0.9, 0.9, 0.75]}>
        <img id="implement-card-icon" src={implementMimiCardIcon} alt="" />
        <TextTitle>{strings.userConfiguration}</TextTitle>
        <TextBody>{strings.implementMimiBody1}</TextBody>
      </TextContainer>
      <ImageList>
        <img id="implement-card-phone" src={implementMimiCardPhone} alt="" />
        <ListContainer>
          {list1}
        </ListContainer>
      </ImageList>
      <TextContainer width={[1, 0.9, 0.9, 0.75]}>
        <img id="os-icon" src={operatingSystemsIcon} alt="" />
        <TextTitle>{strings.audioImplementation}</TextTitle>
        <TextBody>{strings.implementMimiBody2}</TextBody>
      </TextContainer>
      <ImageList>
        <img id="processing-visual-image" src={processingVisual} alt="" />
        <ListContainer>
          {list2}
        </ListContainer>
      </ImageList>
      <PageCTA id="qa-integrate-mimi-button" to="/dashboard">{strings.integrateMimi}</PageCTA>
    </Container>
  );
};

export default ImplementMimiCard;
