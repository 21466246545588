import React from 'react';
import styled, { css } from 'styled-components';

import strings from 'helper/localization/localization.js';

import {
  Col,
} from 'components/Layout/Grid';
import {
  PageCTA,
  SectionContainer,
  TextTitle,
  TextBody,
} from 'components/IntegrateMimiUi';

import { smallTablet, largeTablet, desktop } from 'helper/constants/mediaRules';
import {
  FONT_SIZE_16,
} from 'helper/constants/styles';
import testAppVisual from 'S3/images/test_app_visual.svg';

const Container = styled(SectionContainer)`
  text-align: left;
`;

const TopTextContainer = styled(Col)`
  margin: 0 auto;
  text-align: center;
  padding: 2.5rem 0 0;
  z-index: 2;
  position: relative;

  ${largeTablet(css`
    padding: 3.3rem 0 0;
    margin: 0 3rem;
    text-align: left;
  `)}
`;

const BottomTextContainer = styled(Col)`
  text-align: center;
  margin: 2rem auto;
  line-height: 1.5;
  z-index: 2;
  position: relative;
`;

const ImageContainer = styled(Col)`
  text-align: end;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 18px;
  margin-top: 2rem;

  ${largeTablet(css`
    padding-right: 18px;
    padding-right: 3.2rem;
    flex-direction: row-reverse;
    margin-top: -2.5rem;
  `)}
`;

const CardImage = styled.img`
  width: 40%;

  ${smallTablet(css`
    width: 30%;
  `)}

  ${largeTablet(css`
    width: 30%;
  `)}

  ${desktop(css`
    width: 60%;
  `)}
`;

const FooterLinks = styled.ul`
  text-align: center;
  margin: 0 auto;
  list-style: inside;
  font-size: ${FONT_SIZE_16};
  z-index: 2;
  position: relative;

  ${largeTablet(css`
    margin: 3rem auto;
  `)}

  > li {
    margin-bottom: 1rem;
    font-size: ${FONT_SIZE_16};
  }
`;

const LinksStrings = [
  strings.testEvalList1,
  strings.testEvalList2,
  strings.testEvalList3,
  strings.testEvalList4,
  strings.testEvalList5,
];

const TestEvaluationCard = () => {
  const links = LinksStrings.map(link => (
    <li key={link}>{link}</li>
  ));
  return (
    <Container>
      <TopTextContainer width={[0.9, 0.8, 0.7, 0.6]}>
        <TextTitle>{strings.mimiTestApp}</TextTitle>
        <TextBody>{strings.testAppBody1}</TextBody>
      </TopTextContainer>
      <ImageContainer>
        <CardImage id="test-app-image" src={testAppVisual} alt="" />
      </ImageContainer>
      <BottomTextContainer width={[0.9, 0.9, 0.9, 0.7]}>
        <TextTitle>{strings.supportDocumentation}</TextTitle>
        <TextBody>{strings.testAppBody2}</TextBody>
      </BottomTextContainer>
      <FooterLinks>
        {links}
        <PageCTA id="qa-test-mimi-button" to="/dashboard">{strings.testMimi}</PageCTA>
      </FooterLinks>
    </Container>
  );
};

export default TestEvaluationCard;
