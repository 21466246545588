import React from 'react';

import strings from 'helper/localization/localization';

import PlatformHeader from 'components/Solutions/SolutionsPageHeader';
import TestEvaluationCard from 'components/IntegrateTestEval/TestEvaluationCard';
import testEvaluateHeaderIcon from 'S3/images/test_evaluate_header_icon.svg';

const TestEvaluate = () => (
  <>
    <PlatformHeader
      title={strings.testEvaluate}
      image={testEvaluateHeaderIcon}
      body={strings.testEvalHeaderText}
    />
    <TestEvaluationCard />
  </>
);

export default TestEvaluate;
