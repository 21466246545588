import React from 'react';
import { navigate } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { Router } from '@reach/router';

import { Container } from 'components/Layout/Grid';

import { StyledRightSquare } from 'src/pages/contact-sales';

import { largeDesktop } from 'helper/constants/mediaRules';
import { UNAUTH_ROUTES } from 'helper/constants/constants';
import TestEvaluate from 'pages/solutions/test-evaluate';
import ImplementMimi from 'pages/solutions/implement-mimi';

import SEO from '../components/Seo';

const ContainerStyle = styled(Container)`
  text-align: center;
  padding: 0;

  ${largeDesktop(css`
    justify-content: center;
  `)}
`;

const Solutions = () => {
  const PageDirect = () => {
    // https://github.com/gatsbyjs/gatsby/issues/309
    // navigate refers to `window` which isn't available in SSR
    if (typeof window === 'undefined') {
      return;
    }

    const splitPathName = window.location.pathname.replace(/\/$/, '').split('/');
    const page = splitPathName[splitPathName.length - 1];

    if (!['implement-mimi', 'test-evaluate'].includes(page)) {
      navigate('/');
    }
  };
  PageDirect();

  const images = useStaticQuery(graphql`
    fragment solutionsFluid on File {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query SolutionsPageImageQuery {
      contactRightSquare: file(relativePath: { eq: "images/contact_right_square.png" }) {...solutionsFluid}
    }
  `);


  const { contactRightSquare } = images;

  return (
    <ContainerStyle>
      <SEO title="Solutions" />
      <StyledRightSquare
        style={{ zIndex: 0, position: 'absolute' }}
        id="bg-right-square"
        fluid={contactRightSquare.childImageSharp.fluid}
        alt=""
      />
      <Router basepath={process.env.GATSBY_PATH_PREFIX || ''}>
        <TestEvaluate path={UNAUTH_ROUTES.testEvaluate} />
        <ImplementMimi path={UNAUTH_ROUTES.implementMimi} />
      </Router>
    </ContainerStyle>
  );
};

export default Solutions;
