import React from 'react';

import strings from 'helper/localization/localization';

import PlatformHeader from 'components/Solutions/SolutionsPageHeader';
import ImplementMimiCard from 'components/IntegrateImplementMimi/ImplementMimiCard';
import implementMimi from 'S3/images/implement_mimi.svg';

const ImplementMimi = () => (
  <>
    <PlatformHeader
      title={strings.implementMimi}
      image={implementMimi}
      body={strings.implementMimiHeaderText}
    />
    <ImplementMimiCard />
  </>
);

export default ImplementMimi;
